import { Link } from "react-router-dom";
import "../styles/custom.css";

export default function Banner()
{
    return(
        <>
            <div className="banner-area-three pc-show customContainer">
                <div className="banner-shape">
                    <img src="/assets/img/home-three/banner3.png" alt="Shape" />
                    <img src="/assets/img/home-three/banner2.png" alt="Shape" style={{display: "none"}} />
                    <img src="/assets/img/home-three/banner4.png" alt="Shape" />
                </div>
                <div className="container">
                    <div className="banner-content">
                        <h1 style={{color: 'black'}}>Serving The Best Sizzler In Mumbai</h1>
                        <h3>Delivered to your Doorsteps</h3>
                        <p>Looking for an unforgettable dining experience from the comfort of your own home? Look no further than Our Sizzlers Delivery Kitchen! Our carefully curated recipes offer an authentic and homemade taste that will please even the most discerning palates. We're proud to be the first to bring this level of sizzler cuisine to your doorstep. So Why Wait ? Cafe Interior Customised Pure Veg Sizzlers</p>
                        <div className="banner-btn-wrap">
                            <Link style={{textDecoration: 'none'}} className="cmn-btn orderNow" to="https://letsoow.petpooja.com/">Order Now</Link>
                        </div>
                    </div>
                    <div className="banner-img">
                        <img src="/assets/img/client-image/hero-shot.webp" alt="Banner" />
                    </div>
                </div>
            </div>

            <div className="banner-area-three mobile-show">
                <div className="banner-shape">
                    <img src="/assets/img/home-three/banner3.png" alt="Shape" />
                    <img src="/assets/img/home-three/banner2.png" alt="Shape" style={{display: "none"}} />
                    <img src="/assets/img/home-three/banner4.png" alt="Shape" />
                </div>
                <div className="container">
                    <div className="banner-img" style={{paddingTop: '118px', paddingBottom: '10px'}}>
                        <img src="/assets/img/client-image/hero-shot.webp" alt="Banner" />
                    </div>
                    <div className="banner-content" style={{paddingTop: '0px'}}>
                        <h1>Serving The Best Sizzler In Mumbai</h1>
                        <h3>Delivered to your Doorsteps</h3>
                        <p>Looking for an unforgettable dining experience from the comfort of your own home? Look no further than Our Sizzlers Delivery Kitchen! Our carefully curated recipes offer an authentic and homemade taste that will please even the most discerning palates. We're proud to be the first to bring this level of sizzler cuisine to your doorstep. So Why Wait ? Cafe Interior Customised Pure Veg Sizzlers</p>
                        <div className="banner-btn-wrap">
                            <Link style={{textDecoration: 'none'}} className="cmn-btn orderNow" to="https://letsoow.petpooja.com/">Order Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}