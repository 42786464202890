import { Link } from "react-router-dom";

export default function HeroBanner({title, name})
{
    return(
        <div class="page-title-area page-title-img-one">
            <div class="container">
                <div class="page-title-item">
                    <h2>{title}</h2>
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <i class='bx bx-chevron-right' ></i>
                        </li>
                        <li>{name}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}