import { Link } from "react-router-dom";

export default function Footer()
{
    const d = new Date()
    return(
        <>
            {/* Footer */}
            <footer className="footer-area-two pt-100 pb-70" style={{background: '#000'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-lg-3">
                            <div className="footer-item">
                                <div className="footer-logo">
                                    <Link to="/">
                                        <img src="/assets/img/Logo.webp" alt="Logo" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="footer-item">
                                <div className="footer-service">
                                    <h3>Our Product</h3>
                                    <ul>
                                        <li>
                                            <Link to="/gallery">
                                                <i className='bx bx-chevron-right'></i>
                                                Sizzler
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/menu">
                                                <i className='bx bx-chevron-right'></i>
                                                Dips and Chips
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/menu">
                                                <i className='bx bx-chevron-right'></i>
                                                Salads
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/menu">
                                                <i className='bx bx-chevron-right'></i>
                                                Wraps
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/menu">
                                                <i className='bx bx-chevron-right'></i>
                                                Appetizers
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="footer-item">
                                <div className="footer-service">
                                    <h3>Quick Links</h3>
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                <i className='bx bx-chevron-right'></i>
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/about">
                                                <i className='bx bx-chevron-right'></i>
                                                About Us
                                            </Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/menu">
                                                <i className='bx bx-chevron-right'></i>
                                                Menu
                                            </Link>
                                        </li> */}
                                        <li>
                                            <Link to="/gallery">
                                                <i className='bx bx-chevron-right'></i>
                                                Sizzlers
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                <i className='bx bx-chevron-right'></i>
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <a href="/privacy.html">
                                                <i className='bx bx-chevron-right'></i>
                                                Privacy Policy
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/terms.html">
                                                <i className='bx bx-chevron-right'></i>
                                                Terms & Condition
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/shipping.html">
                                                <i className='bx bx-chevron-right'></i>
                                                Shipping Policy
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="footer-item">
                                <div className="footer-service">
                                    <h3>Contact Us</h3>
                                    <ul>
                                        <li>
                                            <a href="tel:+919892103776">
                                                <i className='bx bx-phone-call'></i>
                                                +91 98921 03776
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:+919821380926">
                                                <i className='bx bx-phone-call'></i>
                                                +91 98213 80926
                                            </a>
                                        </li>
                                        <li>
                                            <a href="mailto:letssizzleit@gmail.com">
                                                <i className='bx bx-message-detail'></i>
                                                letssizzleit@gmail.com
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="mailto:hello@restant.com">
                                                <i className='bx bx-message-detail'></i>
                                                hello@restaurant.com
                                            </a>
                                        </li> */}
                                        <li>
                                            <i className='bx bx-location-plus' ></i>
                                            Shop No. 3 & 2, Girdhari Sadan, ground flr,  Sena Bhuvan Path, Shiv Sena Bhuvan gate lane,  Shivaji Park, Dadar (west)  Mumbai - 400028
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {/* Copyright */}
            <div className="copyright-area  copyright-area-two" style={{background: '#000'}}>
                <div className="container">
                    <div className="copyright-item">
                        <p>All Rights Reserved By Let's Sizzle It @{d.getFullYear()}. Design By <Link to="https://divtech.in/" target="_blank">Div Tech</Link>.</p>
                    </div>
                </div>
            </div>
        </>
    );
}