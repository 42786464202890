import { useEffect } from "react";
import Footer from "./components/Footer";
import HeroBanner from "./components/HeroBanner";
import Navbar from "./components/Navbar";

export default function Contact()
{
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])
    return(
        <>
            {/* Navbar */}
            <Navbar page={"contact"} />

            {/* Hero Banner */}
            <HeroBanner name={"Contact Us"} title={"Contact Us"} />

            {/* Contact Us Section */}
            <div className="customContainer contact-location-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        {/* Branch Phone Information */}
                        <div className="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                            <div className="location-item">
                                <img src="assets/img/home-one/service-shape.png" alt="Location" />
                                <i className="bx bxs-phone-call"></i>
                                <ul>
                                    <li>
                                        <a href="tel:+919892103776">(Namita Khambatta) +91 98921 03776</a>
                                    </li>
                                    <li>
                                        <a href="tel:+919821380926">(Hemal Khambatta) +91 98213 80926</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* Branch Location Information */}
                        <div className="col-sm-6 col-lg-4">
                            <div className="location-item">
                                <img src="assets/img/home-one/service-shape.png" alt="Location" />
                                <i className="bx bxs-location-plus"></i>
                                <ul>
                                    <li>Shop No. 3 & 2, Girdhari Sadan, ground flr,  Sena Bhuvan Path, Shiv Sena Bhuvan gate lane,  Shivaji Park, Dadar (west)  Mumbai - 400028</li>
                                </ul>
                            </div>
                        </div>
                        {/* Branch Timings */}
                        <div className="col-sm-6 col-lg-4">
                            <div className="location-item">
                                <img src="assets/img/home-one/service-shape.png" alt="Location" />
                                <i className="bx bx-mail-send"></i>
                                <p style={{textAlign: 'center'}}><a style={{color: '#000'}} href="mailto:letssizzleit@gmail.com">letssizzleit@gmail.com</a></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {/* Social Media Links */}
                        <div className="col-sm-6 col-lg-6">
                            <div className="location-item">
                                <img src="assets/img/home-one/service-shape.png" alt="Location" />
                                <ul style={{ marginBottom: '20px' }}>
                                    <li>Follow Us</li>
                                </ul>
                                <ul>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i className="bx bxl-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i className="bx bxl-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i className="bx bxl-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i className="bx bxl-youtube"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* Working Hours */}
                        <div className="col-sm-6 col-lg-6">
                            <div className="location-item">
                                <img src="assets/img/home-one/service-shape.png" alt="Location" />
                                <i className="bx bxs-time-five"></i>
                                <ul>
                                    <li>10:00 AM to 7:00 PM</li>
                                    <li>(Monday to Sunday)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Map */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.892666422361!2d72.83796107363156!3d19.024450753591925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cfeb9964e163%3A0x2ffdb4750bfc7c2f!2sLet's%20Sizzle%20It*21%20Sizzlers%20%26%20More.!5e0!3m2!1sen!2sin!4v1728027805016!5m2!1sen!2sin" width="100%" height="450" style={{border: '0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            {/* Footer */}
            <Footer />
        </>
    );
}