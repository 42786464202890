import { Link } from "react-router-dom";
import HeroBanner from "./components/HeroBanner";
import Navbar from "./components/Navbar";
import OwlCarousel from 'react-owl-carousel';
import Footer from "./components/Footer";
import { useEffect } from "react";

export default function About()
{
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])
    const options = {
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
            0: { items: 1 },
            600: { items: 2 },
            1000: { items: 3 },
        },
    };
    return(
        <>
            {/* Navbar */}
            <Navbar page={"about"} />

            {/* Banner */}
            <HeroBanner title={"About Us"} name={"About"} />

            {/* Story */}
            <section className="customContainer story-area pt-100 pb-70">
                <div className="story-shape">
                    <img src="/assets/img/about/story3.png" alt="Shape" />
                </div>
                <div className="container">
                    <div className="story-head">
                        <h1 className="mb-5">Meet The Founders</h1>
                        <div className="row">
                            <div className="col-sm-6 col-lg-6">
                                <div className="story-item">
                                    <img style={{width:'300px', height:'350px'}} src="/assets/img/client-image/namita.webp" alt="Story" />
                                    <h3>Namita Khambatta</h3>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-6">
                                <div className="story-item">
                                    <img style={{width:'300px', height:'350px'}} src="/assets/img/client-image/hemal.webp" alt="Story" />
                                    <h3>Hemal Khambatta</h3>
                                </div>
                            </div>
                        </div>
                        <p>
                            Welcome to Let's Sizzle It! - where culinary magic meets entrepreneurial flair, all thanks to the dynamic duo behind the scenes - Namita and Hemal Khambatta. 
                        </p>
                        <br />
                        <p>
                            Namita, with a rich background in advertising and graphic design, is also a certified florist and corporate decor professional. Her true passion, however, lies in the kitchen. Always eager to experiment with new recipes, Namita finds immense joy in cooking and serving delicious meals to friends and family. She longed to bring something unique to the hospitality space, and her love for food became the spark for this venture. 
                        </p>
                        <br />
                        <p>
                            Hemal, on the other hand, is an ad-film maker with a deep passion for food. Always brimming with ideas and unable to sit idle, Hemal's adventurous spirit perfectly complements Namita's culinary creativity. 
                        </p>
                        <br />
                        <p>
                            During the COVID lockdown, as boredom struck, this power couple had a lightbulb moment. They realized that people were craving restaurant-quality food but couldn't safely venture out. Thus, the idea of "Let's Sizzle It!" was born. Serving piping Hot sizzlers to your home. An idea no one ever thought would be possible to execute. What started as an experiment to satisfy the hungry souls around them quickly turned into a full-fledged business. From serving their first sizzler to friends to fulfilling over 5000 orders in two years, their journey has been nothing short of sizzling! 
                        </p>
                        <br />
                        <p>
                            Let's Sizzle It is now like an extension of Namita's home kitchen, designed and curated by both founders. This venture is a testament to their entrepreneurial spirits and their unwavering passion for creating delightful food experiences. So, get ready to indulge in a sizzler adventure crafted with love and served with flair by Namita and Hemal!
                        </p>
                    </div>
                </div>
            </section>

            {/* Ad Banner */}
            <section className="download-area-two pt-100 pb-70">
                <div className="download-shape">
                    <img src="/assets/img/home-one/reservation-shape.png" alt="Download" />
                </div>
                <div className="container">
                    <div className="row">   
                        <div className="col-lg-12">
                            <div className="download-item" style={{textAlign: 'center'}}>
                                <h2>SERVING BEST SIZZLERS IN MUMBAI <br/> Delivered to your Doorsteps
                                </h2>
                            <div className="banner-btn-wrap">
                            <Link className="cmn-btn orderNow" to="https://letsoow.petpooja.com/">Order Now</Link>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Services */}
            <section className="customContainer service-area ptb-100">
                <div className="container">
                    <div className="section-title">
                        <h1>What We Offer</h1>
                        <p>At our company, we provide a range of services designed to meet your needs and enhance your experience. Explore our offerings below!</p>
                    </div>
                    <OwlCarousel className="service-slider owl-theme" {...options}>
                        <div className="service-item">
                            <a href="#">
                                <img src="/assets/img/home-one/service1.png" alt="Fresh Healthy Food" />
                                <img className="service-shape" src="/assets/img/home-one/service-shape.png" alt="Service Shape" />
                                <h3>Fresh Healthy Food</h3>
                                {/* <p>Fresh food is food that has not been preserved and has not spoiled yet. For vegetables and fruits, this means...</p> */}
                            </a>
                        </div>
                        <div className="service-item">
                            <a href="#">
                                <img src="/assets/img/home-one/service2.png" alt="Free Fast Home Delivery" />
                                <img className="service-shape" src="/assets/img/home-one/service-shape.png" alt="Service Shape" />
                                <h3>Fast Home Delivery</h3>
                                {/* <p>Fast delivery ensures your fresh food reaches you quickly without compromising quality.</p> */}
                            </a>
                        </div>
                        <div className="service-item">
                            <a href="#">
                                <img src="/assets/img/home-one/service3.png" alt="Discount Voucher" />
                                <img className="service-shape" src="/assets/img/home-one/service-shape.png" alt="Service Shape" />
                                <h3>Pure Veg & Jain Food</h3>
                                {/* <p>Get special discounts on your orders with our vouchers for great savings.</p> */}
                            </a>
                        </div>
                    </OwlCarousel>
                </div>
            </section>

            {/* Footer */}
            <Footer />
        </>
    );
}