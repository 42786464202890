import Footer from "./components/Footer";
import HeroBanner from "./components/HeroBanner";
import Navbar from "./components/Navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import { useEffect, useState } from "react";

export default function Gallery()
{
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])
    const shashlik = [
        {url: "/assets/img/client-image/p8.jpg", name: "Cutlet Shashlik", id: 1, description: ["Corn Coriander Rice OR Butter Garlic Noodles", "1 Vegetable CutletVeggies - French Beans", "Carrot", "Cauliflower", "Baby Corns", "Broccoli", "Caramelized onions", "Green peas", "Bell Peppers", "SpinachStuffed Tomato Baked -Baked Beans", "Paneer", "Bell Peppers", "Topped with CheeseFrench Fries OR Mashed Potato"]},
        {url: "/assets/img/client-image/p10.jpg", name: "Paneer Shashlik", id: 2, description: ["Corn Coriander Rice OR Butter Garlic Noodles", "2 Sticks Grilled Paneer", "Onions", "Capsicum+Veggies - French Beans", "Carrot", "Cauliflower", "Baby Corns", "Broccoli", "Caramelized onions", "Green peas", "Bell Peppers", "SpinachStuffed Tomato Baked -Baked Beans", "Paneer", "Bell Peppers", "Topped with Cheese", "French Fries Or Mashed Potato"]},
        {url: "/assets/img/client-image/p9.jpg", name: "Combo Shashlik", id: 3, description: ["1 Cutlet", "1 Stick Paneer", "Corn Coriander Rice OR Butter Garlic Noodles", "Onions", "Capsicum+Veggies - French Beans", "Carrot", "Cauliflower", "Baby Corns", "Broccoli", "Caramelized onions", "Green peas", "Bell Peppers", "SpinachStuffed Tomato Baked -Baked Beans", "Paneer", "Bell Peppers", "Topped with Cheese", "French Fries Or Mashed Potato"]}
    ];

    const chinese = [
        {url: "/assets/img/client-image/p4.jpg", name: "Paneer Chilli", id: 4, description: ["Burnt Garlic Fried Rice OR Hakka Noodles", "Paneer ChilliVeggies - Carrot", "French Beans", "Baby Corn", "Broccoli", "Cauliflower", "Zucchini", "Bell Peppers", "Caramelized Onions", "Spring Onions", "American Corn", "Sweet and Sour WaterChestnutsTomato - Stuffed with Corn Salad", "French Fries"]},
        {url: "/assets/img/client-image/p11.jpg", name: "Manchurian Madness", id: 5, description: ["Burnt Garlic Fried Rice OR Hakka Noodles", "4 Manchurian Balls", "Veggies - Carrot", "French Beans", "Baby Corn", "Broccoli", "Cauliflower", "Zucchini", "Bell Peppers", "Caramelized Onions", "Spring Onions", "American Corn", "Sweet and Sour WaterChestnuts", "Tomato - Stuffed with Corn Salad", "French Fries"]}
    ];

    const thai = [
        {url: "/assets/img/client-image/p2.jpg", name: "Tofu Thai", id: 6, description: ["Pad Thai Noodles", "Com Potato Croquets OR Basil Stir Fried Tofu", "Veggies - French Beans", "Carrot", "Broccoli", "Zucchini", "Baby Cors", "Bell Peppers", "Spring Onions", "American Corn", "Tomato Stuffed with Corn Salad", "Honey Chilli Fries"]},

        {url: "/assets/img/client-image/p7.jpg", name: "Croquette Thai", id: 7, description: ["Thai Fragrant Rice", "Com Potato Croquets OR Basil Stir Fried Tofu", "Carrot", "Zucchini", "Baby Corns", "Bell Peppers", "Spring Onions", "American Corn", "Tomato Stuffed with Com Salad", "Honey Chilli Fries"]}
    ];

    const cheesy = [
        {url: "/assets/img/client-image/p1.jpg", name: "Veg Paprika", id: 8, description: ["Herbed Rice", "Grilled Basil Paneer", "Veggies - French beans", "Carrot", "Babycorn", "Bell Peppers", "Broccoli", "Zucchini", "Olives", "Cherry Tomatoes", "Jacket Potato", "French Fries"]},
        {url: "/assets/img/client-image/p6.jpg", name: "Fussili Pasta", id: 9, description: ["Fusilli Pasta in Pink Sauce", "Cheese Corn Balls", "Veggies - French beans", "Carrot", "Babycorn", "Bell Peppers", "Broccoli", "Zucchini", "Olives", "Cherry Tomatoes", "Jacket Potato", "French Fries"]},
    ];

    const mexican = [
        {url: "/assets/img/client-image/p5.jpg", name: "Mexican Sizzler", id: 10, description: ["Mexican Rice", "Grilled Peri Peri Paneer Refried Beans", "Guacamole", "Veggies - Bell Peppers", "Zucchini", "French beans", "Carrot", "Broccoli", "Babycorn", "Caramelized Onions", "Tomato Baked and Stuffed with Black beans and Corn Salad", "French Fries"]}
    ];

    const pavbhaji = [
        {url: "/assets/img/client-image/p3.jpg", name: "Pav Bhaji", id: 11, description: ["Tawa Pulav, Bhaji", "Veggies - French beans", "Carrot", "Green Peas", "Cauliflower", "Bell Peppers", "Caramelized Onions", "French Fries"]}
    ];

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    return(
        <>
            {/* Navbar */}
            <Navbar page={"gallery"} />

            {/* Hero Banner */}
            <HeroBanner title={"Gallery"}  name={"Gallery"} />

            {/* Products */}
            <div className="customContainer feature-area pt-100 pb-70">
                <div className="container">

                    {/* Shahlik */}
                    <div className="row mb-5">
                        <h1>Veg Shahlik Sizzlers</h1>
                        {
                            shashlik.map(p => <div key={p.id} className="col-sm-6 col-lg-4">
                                <div className="feature-item" onClick={() => handleProductClick(p)} style={{ cursor: 'pointer' }}>
                                    <img src={p.url} alt="Feature" />
                                    <div className="feature-inner">
                                        <ul>
                                            <li>
                                                <span>What's in {p.name}</span>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className='bx bx-right-arrow-alt'></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                    
                    {/* Chinese */}
                    <div className="row mb-5">
                        <h1>Sizzling Chinese Sizzlers</h1>
                        {
                            chinese.map(p => <div key={p.id} className="col-sm-6 col-lg-4">
                                <div className="feature-item" onClick={() => handleProductClick(p)} style={{ cursor: 'pointer' }}>
                                    <img src={p.url} alt="Feature" />
                                    <div className="feature-inner">
                                        <ul>
                                            <li>
                                                <span>What's in {p.name}</span>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className='bx bx-right-arrow-alt'></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>

                    {/* Thai */}
                    <div className="row mb-5">
                        <h1>Spicy Thai Sizzlers</h1>
                        {
                            thai.map(p => <div key={p.id} className="col-sm-6 col-lg-4">
                                <div className="feature-item" onClick={() => handleProductClick(p)} style={{ cursor: 'pointer' }}>
                                    <img src={p.url} alt="Feature" />
                                    <div className="feature-inner">
                                        <ul>
                                            <li>
                                                <span>What's in {p.name}</span>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className='bx bx-right-arrow-alt'></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>

                    {/* Cheesy Italian */}
                    <div className="row mb-5">
                        <h1>Cheesy Italian Sizzlers</h1>
                        {
                            cheesy.map(p => <div key={p.id} className="col-sm-6 col-lg-4">
                                <div className="feature-item" onClick={() => handleProductClick(p)} style={{ cursor: 'pointer' }}>
                                    <img src={p.url} alt="Feature" />
                                    <div className="feature-inner">
                                        <ul>
                                            <li>
                                                <span>What's in {p.name}</span>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className='bx bx-right-arrow-alt'></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>

                    {/* Mexican */}
                    <div className="row mb-5">
                        <h1>Mexican Sizzlers</h1>
                        {
                            mexican.map(p => <div key={p.id} className="col-sm-6 col-lg-4">
                                <div className="feature-item" onClick={() => handleProductClick(p)} style={{ cursor: 'pointer' }}>
                                    <img src={p.url} alt="Feature" />
                                    <div className="feature-inner">
                                        <ul>
                                            <li>
                                                <span>What's in {p.name}</span>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className='bx bx-right-arrow-alt'></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>

                    {/* Pav Bhaji */}
                    <div className="row mb-5">
                        <h1>Pav Bhaji Sizzlers</h1>
                        {
                            pavbhaji.map(p => <div key={p.id} className="col-sm-6 col-lg-4">
                                <div className="feature-item" onClick={() => handleProductClick(p)} style={{ cursor: 'pointer' }}>
                                    <img src={p.url} alt="Feature" />
                                    <div className="feature-inner">
                                        <ul>
                                            <li>
                                                <span>What's in {p.name}</span>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className='bx bx-right-arrow-alt'></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>

            {/* Modal */}
            <Modal show={showModal} onHide={handleClose} centered size="md" animation={true}>
                <Modal.Header style={{backgroundImage: `url('/assets/img/rusted-bg.webp')`}} closeButton className="text-light">
                    <Modal.Title className="text-uppercase">{selectedProduct?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column align-items-center p-4">
                    <img 
                        src={selectedProduct?.url} 
                        alt={selectedProduct?.name} 
                        className="img-fluid mb-4" 
                        style={{ borderRadius: '10px', boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)' }} 
                    />
                    <p style={{lineHeight: '1.6', textAlign: 'left', color: '#333' }}>
                        <ul>
                            {
                                selectedProduct?.description.map(d=><li>{d}</li>)
                            }
                        </ul>
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="outline-dark" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Footer */}
            <Footer />
        </>
    );
}