import { useEffect } from "react";
import Navbar from "./components/Navbar";
import HeroBanner from "./components/HeroBanner";
import Footer from "./components/Footer";

export default function Menu()
{
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    return(
        <>
            {/* Navbar */}
            <Navbar page={"menu"} />

            {/* Hero Banner */}
            <HeroBanner name={"Beyond Sizzler"} title={"Beyond Sizzler"} />

            {/* Menu Section */}
            <div className="customContainer contact-location-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <img src="/assets/img/client-image/menu/p1.jpg" alt="" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <img src="/assets/img/client-image/menu/p2.jpg" alt="" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <img src="/assets/img/client-image/menu/p3.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </>
    );
}